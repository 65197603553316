<template>
<nav class="navbar navbar-expand-lg navbar-light bg-light">
  
    <div class="container-fluid">

        <div class="" id="navbarSupportedContent ml-auto">
            <ul class="navbar-nav mr-auto">
                <li class="nav-item"><router-link to="/" class="nav-link">Home</router-link></li>
                <li class="nav-item"><router-link to="/assets" class="nav-link" v-if="authenticated">Assets</router-link></li>
                <li class="nav-item"><router-link to="/adhocLabel" class="nav-link" v-if="authenticated">Adhoc Label</router-link></li>
                <li class="nav-item"><router-link to="/about" class="nav-link" v-if="authenticated">About</router-link></li>
            </ul>
        </div>
        <div class="container-fluid" style="width:500px; float: right" v-if="authenticated">
            <form class="d-flex" @submit.prevent="search" >
                <input class="form-control me-2" type="search" placeholder="Search" aria-label="Search" v-model="searchString">
                <button class="btn btn-outline-success" type="submit" @submit="search" >Search</button>
            </form>
        </div>
        <div class="ml-auto">
            <ul class="navbar-nav ">
                <li class="nav-item dropdown right">
                    <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">{{dropdownDisplay}}</a>
                    <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
                        <li v-for="company in myCompanies" :key="company.companyId"><a class="dropdown-item" href="#" v-on:click="setCompany(company.companyId)">{{company.companyName}}</a></li>
                        <li><hr class="dropdown-divider"></li>

                        <li v-if="authenticated != true"><a class="dropdown-item" :href="baseUrl + '/api/auth/externallogin?provider=Google&returnUrl=' + baseUrl + '/'">Google</a></li>

                        <li v-if="authenticated == true"><a class="dropdown-item" :href="baseUrl + '/api/auth/ExternalLogOut?returnUrl=' + baseUrl">Log Out</a></li>
                        <li v-if="authenticated == true"><router-link class="dropdown-item" to="/companysettings">Settings</router-link></li>
                        <li v-if="authenticated == true && globalAdmin == true"><router-link class="dropdown-item" to="/globalSettings">Global Settings</router-link></li>
                        <!--li> <GoogleLogin :callback="loginCallback"></GoogleLogin> </li-->
                    </ul>
                </li>
            </ul>
        </div>
    </div>  
    </nav>
</template>


<script lang="ts">
    import { defineComponent } from 'vue';

    export default defineComponent({
        name: 'NavBar',
        props: {
        },
        data() {
            return {
                myCompanies: [] as {
                    companyId: Number,
                    companyName: string,
                    isDefault: boolean
                }[],
                roles: [] as string[],
                profile: {} as {
                    userName: string,
                    email: string,
                    roles: string[]
                    companies: any,
                
                },
                companyId: 0 as Number,
                authenticated: false as boolean,
                globalAdmin: false as boolean,
                dropdownDisplay: "" as string,
                baseUrl: location.protocol + "//" + location.host,
                searchString: "",
                firstScan: 0 as Number,
                scannedBarcode: "" as string,
                scanGapMax: 300 as Number
            };
        },

        methods: {
            async loginCallback(response: any) {
                try{
                    console.log("Handle the response", response)
                    console.log(response.credential);
                    const res = await this.axios.post('auth/authenticate', {IdToken: response.credential} )
                    console.log(res)
                } catch(error){
                    console.log(error);
                }
            },
            async search() {
                console.log("Searching" + this.searchString)
                const res = await this.axios.get(`company/${this.companyId}/search/${this.searchString}`);
                console.log(res.data)
                console.log(res.data.length)

                if (res.data.length === 1) {
                    console.log("Only 1 found - going straight there")
                    this.$router.push({ name: 'AssetDetails', params: { id: res.data[0].id } })
                    this.$emit("search-complete");
                }
                else {
                    this.$router.push({ name: 'SearchResults', query: { searchString: this.searchString} })
                }

                this.searchString = "";
            },

            setCompany(companyId: Number){
                console.log(companyId);
                this.companyId = companyId
                this.$cookies.set("companyId", companyId)
                let currentCompany = this.myCompanies.find(co => { return co.companyId == this.companyId });
                this.dropdownDisplay = currentCompany?.companyName ?? "";         
                this.$router.push("/");

            },

            async handleScan(barcode: string) {
                this.searchString = barcode;
                await this.search();
            },

            async getData() {
                try {
                    const response = await this.axios.get(
                        "my/profile"
                    );
                    // JSON responses are automatically parsed.
                    this.myCompanies = response.data.companies;
                    this.profile = response.data;
                    this.roles = response.data.roles;
                    console.log(this.profile)
                    if (!this.$cookies.isKey("companyId")) {
                        console.log("Company not set");
                        let defaultCompany = this.myCompanies.find(co => { return co.isDefault })
                        if (defaultCompany != null) {
                            this.$cookies.set("companyId", defaultCompany?.companyId)
                            this.companyId = defaultCompany.companyId
                        }

                    } else {
                        this.companyId = this.$cookies.get("companyId")
                    }
                    this.authenticated = true;
                    let globalAdminRole = this.roles.find(x => { return x == "GlobalAdmin"} );
                    if (globalAdminRole != null) {
                        this.globalAdmin = true;
                    }
                    let currentCompany = this.myCompanies.find(co => { return co.companyId == this.companyId });
                    this.dropdownDisplay = currentCompany?.companyName ?? "";
                } catch (error) {
                    this.$router.push("Home");
                    console.log(error);
                }
            },

            handleKeyEvent(e: KeyboardEvent) {

                if (e.altKey && e.key == "n") {
                    console.log("New Asset");
                    this.$router.push({ name: "AssetCreate" })
                }
                console.log("FirstScan: " + this.firstScan)
                let limit = +this.firstScan + +this.scanGapMax
                console.log("FirstScan: " + this.firstScan + ", limit: " + limit)
                if ((+this.firstScan + +this.scanGapMax) > +Date.now()) {
                    console.log("Adding key" + e.key)
                    if (e.key == "Enter") {
                        console.log("Scanned Barcode: " + this.scannedBarcode);
                        let res = this.scannedBarcode;
                        this.scannedBarcode = "";
                        this.firstScan = 0;
                        this.handleScan(res);
                    } else {
                        let keycode = e.keyCode;

                        let valid =
                            (keycode > 47 && keycode < 58) || // number keys
                            keycode == 32 || keycode == 13 || // spacebar & return key(s) (if you want to allow carriage returns)
                            (keycode > 64 && keycode < 91) || // letter keys
                            (keycode > 95 && keycode < 112) || // numpad keys
                            (keycode > 185 && keycode < 193) || // ;=,-./` (in order)
                            (keycode > 218 && keycode < 223);   // [\]' (in order)
                        if (valid) {
                            this.scannedBarcode += e.key
                        }
                    }
                }
                else {
                    console.log("Resetting")
                    this.scannedBarcode = "";
                    this.firstScan = 0;
                }
                if (this.firstScan == 0) {
                    this.firstScan = +Date.now()
                    this.scannedBarcode = e.key
                    console.log("FirstScan:" + this.firstScan + ", MaxGap: " + this.scanGapMax)
                }
            }
        },

        created() {
            this.dropdownDisplay = "Not logged in"
            this.getData();
        }, 

        mounted() {
            document.addEventListener('keydown', this.handleKeyEvent.bind(this));

        },

        beforeUnmount() {
            document.removeEventListener('keydown', this.handleKeyEvent);

        }
    });
</script>