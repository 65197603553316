<template>
    <div class="container">
        <form @submit.prevent="printlabel">
            <div class="mb-3">
                <label for="labe.lasset" class="form-label">Asset</label>
                <input class="form-control" type="text" name="label" v-model="label.asset" />
            </div>
            <div class="mb-3">
                <label for="testDate" class="form-label">Test Date</label>
                <input class="form-control" type="date" name="testDate" v-model="label.testDate" />
            </div>
            <div class="mb-3">
                <label for="expires" class="form-label">Expires</label>
                <input class="form-control" type="date" name="expires" v-model="label.expires" />

            </div>
            <div class="mb-3">
                <label for="testedBy" class="form-label">Tested By</label>
                <input class="form-control" type="text" name="testedBy" v-model="label.testedBy" />
            </div>
            <div class="mb-3">
                <label for="company" class="form-label">Company</label>
                <input class="form-control" type="text" name="company" v-model="label.company" />
            </div>
            <div class="mb-3">
                <label for="phone" class="form-label">Phone</label>
                <input class="form-control" type="text" name="phone" v-model="label.phone" />
            </div>
            <button class="btn btn-default" type="submit">Submit</button>
        </form>
    </div>

</template>


<script>
import { printZpl } from '@/printing'

export default {
    name: 'AdhocLabel',
    props: {
    },
    data() {
        return {
            label: {
                asset: "",
                testDate: new Date().toISOString().slice(0, 10),
                expires: "",
                testedBy: "",
                company: "",
                phone: ""
            },
            companyId: this.$cookies.get('companyId'),
        }
    },
    async created() {

        let companyId = this.$cookies.get('companyId');
        var d = new Date()
                d.setDate(d.getDate() + 365)
                this.label.expires = d.toISOString().slice(0, 10)

                var profile = await this.axios.get(`my/profile?companyId=${companyId}`)   
                console.log(profile.data)
                this.label.testedBy = profile.data.name
                this.label.company = profile.data.currentCompany.companyName
                this.label.phone = profile.data.currentCompany.phone

    },
    methods: {
        async search() {
            console.log("Searching" + this.searchString)
            const res = await this.axios.get(`company/${this.companyId}/search/${this.searchString}`);
            console.log(res.data)
            console.log(res.data.length)
        },
        async printlabel(){
            console.log(this.label);
            console.log(this.expires);

            var zpl = `^XA~TA000~JSN^LT0^MNW^MTD^PON^PMN^LH0,0^JMA^PR5,5~SD15^JUS^LRN^CI0^XZ
^XA
^MMT
^PW825
^LL0451
^LS0
^FT121,402^A0B,28,28^FH\\^FDTested^FS
^FT145,402^A0B,28,28^FH\\^FDExpires^FS
^FT97,402^A0B,28,28^FH\\^FDAsset:^FS
^FT169,402^A0B,28,28^FH\\^FDTested By:^FS
^FT58,402^A0B,45,52^FH\\^FDPASS^FS
^FT627,402^A0B,23,24^FH\\^FDTested to: ^FS
^FT655,402^A0B,23,24^FH\\^FDAS/NZS 3760^FS
^FT683,402^A0B,23,24^FH\\^FDAS/NZS 3761^FS
^FT711,402^A0B,23,24^FH\\^FDAS/NZS 3762^FS
^FT744,402^A0B,28,28^FH\\^FDCompany:^FS
^FT776,402^A0B,28,28^FH\\^FDPhone:^FS
^FT97,266^A0B,28,28^FH\\^FD${this.label.asset}^FS
^FT121,266^A0B,28,28^FH\\^FD${this.label.testDate}^FS
^FT145,266^A0B,28,28^FH\\^FD${this.label.expires}^FS
^FT169,266^A0B,28,28^FH\\^FD${this.label.testedBy}^FS
^FT744,266^A0B,28,28^FH\\^FD${this.label.company}^FS
^FT776,266^A0B,28,28^FH\\^FD${this.label.phone}^FS
^FO409,26^GB0,400,8^FS
^PQ1,0,1,Y^XZ
`;
            await this.axios.post(`/company/${this.companyId}/audit`, { action: "Printed asset test", entity: "AdHoc", body: zpl });
            printZpl(zpl);
        }
    }
}
</script>